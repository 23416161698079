import ContactForm from "./ContactForm";
import './App.css';

function App() {

  return (
    <div className="App">
        <div className="Row">
          <div className="ColumnA">
            <div className="Title">
              <h1>Expressa-te!</h1>
            </div>
            <div className="SubTitle">
              <h3>Em breve! Deixa o teu contacto e para acesso a promos de lançamento.</h3>
            </div>
          </div>
          <div className="ColumnB">
            <img src="/splash.png" alt="Product" />
          </div>
        </div>
        <div className="Row">
          <div className="Subscriptions">
            <ContactForm />
          </div>
        </div>
      <div className="Footer">
        <p>&copy; 2023 Tapamamas. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default App;
